
import { toResult } from '@/utils/toResult'
import { defineComponent, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { basicDataAPI } from '../../api'

export default defineComponent({
  name: '',
  setup() {
    const router = useRouter()
    const data = reactive({
      tableData: [
        {
          name: '最近登录时间',
          value: '-',
          name2: '控车模式',
          value2: '-'
        },
        {
          name: '车辆总数',
          value: '-',
          name2: '车辆（已完备状态）',
          value2: '-'
        },
        {
          name: '司机总人数',
          value: '-',
          name2: '司机（在职）',
          value2: '-'
        },
        {
          name: '合同总份数',
          value: '-',
          name2: '合同（进行中）',
          value2: '-'
        },
        {
          name: '基础车辆初始可用数',
          value: '-',
          name2: '基础车辆增量数',
          value2: '-'
        },
        {
          name: '收费车辆初始可用数',
          value: '-',
          name2: '收费车辆增量数',
          value2: '-'
        },
        {
          name: '设备数',
          value: '-',
          name2: '车辆数',
          value2: '-'
        },
        {
          name: '司机数',
          value: '-'
        }
      ]
    })
    const companyUuid = router.currentRoute.value.query.uuid
    ;(async () => {
      const p = { companyUuid }
      const [err, res] = await toResult(basicDataAPI(p))
      if (err) return
      const strictlyControl = res.strictlyControl ? '开' : '关'
      const maintainControl = res.maintainControl ? '开' : '关'
      data.tableData = [
        {
          name: '最近登录时间',
          value: res.loginTime || '-',
          name2: '控车模式',
          value2: `严格-${strictlyControl}  保养-${maintainControl}`
        },
        {
          name: '车辆总数',
          value: res.allCarCount || '-',
          name2: '车辆（已完备状态）',
          value2: res.preparedCarCount || '-'
        },
        {
          name: '司机总人数',
          value: res.allDriverCount || '-',
          name2: '司机（在职）',
          value2: res.workDriverCount || '-'
        },
        {
          name: '合同总份数',
          value: res.allContractCount || '-',
          name2: '合同（进行中）',
          value2: res.underwayContractCount || '-'
        },
        {
          name: '基础车辆初始可用数',
          value: res.basicCarNum || '-',
          name2: '基础车辆增量数',
          value2: res.basicCarIncreaseNum || '-'
        },
        {
          name: '收费车辆初始可用数',
          value: res.chargeCarNum || '-',
          name2: '收费车辆增量数',
          value2: res.chargeCarIncreaseNum || '-'
        },
        {
          name: '设备数',
          value: res.ownerAllDeviceCount || '-',
          name2: '车辆数',
          value2: res.ownerAllCarCount || '-'
        },
        {
          name: '司机数',
          value: res.ownerAllDriverCount || '-'
        }
      ]
    })()

    return {
      ...toRefs(data)
    }
  }
})
