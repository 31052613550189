
import CTable from '@/components/c-table'
import { getIndexConfig, getTableConfig } from './config'
import { subordinateCompanyListAPI, disassociateAPI } from '../../api'
import { btnName } from '@/utils/index'
import { defineComponent, toRefs, reactive, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { toResult } from '@/utils/toResult'
export default defineComponent({
  components: {
    CTable
  },
  props: {
    rowData: Object
  },
  setup(props) {
    const data = reactive({
      ...getIndexConfig()
    })
    // 表单组件变量
    const tableConfig = reactive({
      ...getTableConfig()
    })

    const getListCompFn = async () => {
      const p = {
        companyUuid: props.rowData.uuid,
        currPage: tableConfig.pagination.currentPage,
        pageSize: tableConfig.pagination.pageSize
      }
      const [err, res] = await toResult(subordinateCompanyListAPI(p))
      if (err) return
      console.log(res)
      tableConfig.data = res.data
    }

    const cancel = row => {
      ElMessageBox.confirm('确定取消关联吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        disassociateAPI({ companyUuid: row.uuid }).then(() => {
          ElMessage.success('操作成功')
          getListCompFn()
        })
      })
    }

    onMounted(() => {
      getListCompFn()
    })

    return {
      tableConfig,
      btnName,
      getListCompFn,
      cancel,
      ...toRefs(data)
    }
  }
})
