import { renderSlot as _renderSlot, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image_viewer = _resolveComponent("el-image-viewer")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clickImg && _ctx.clickImg(...args))),
      style: {"cursor":"pointer"}
    }, [
      _renderSlot(_ctx.$slots, "default", { images: _ctx.images })
    ]),
    (_ctx.isViewer)
      ? (_openBlock(), _createBlock(_component_el_image_viewer, {
          key: 0,
          "url-list": _ctx.currentImages,
          onClose: _ctx.handleViewerClose
        }, null, 8, ["url-list", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}