
import { defineComponent, reactive, toRefs } from 'vue'
import CTable from '@/components/c-table/index.vue'
import { recordCompanyAPI } from '../../api'
import { toResult } from '@/utils/toResult'
import { useRouter } from 'vue-router'
export enum isCompel {
  强制付费 = 1,
  可暂不付费
}
const tableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否开启索引
    isIndex: true,
    // table 索引 label
    indexLabel: '序号',
    // height: 300,
    // table 是否为斑马纹
    stripe: true,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: false,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'roleName',
      label: '试用功能权限',
      width: '160'
    },
    {
      key: 'newRoleName',
      label: '新功能版本',
      width: '120'
    },
    {
      key: 'title',
      label: '套餐标题',
      width: '100'
    },
    {
      key: 'roleName',
      label: '功能权限',
      width: '110'
    },
    {
      key: 'price',
      label: '单车(天/元)',
      width: '85'
    },
    {
      key: 'carBasicAnnualFee',
      label: '单车基础年费',
      width: '110'
    },
    {
      key: 'carChargeAnnualFee',
      label: '单车收费年费',
      width: '140'
    },
    {
      key: 'carChargeDailyFee',
      label: '单车收费（天/元）',
      width: '140'
    },
    {
      key: 'withholdingRate',
      label: '代扣费率',
      width: '110',
      formatter: (row, col, val) => { return val || '-' }
    },
    {
      key: 'signTime',
      label: '签订日期',
      width: '150'
    },
    {
      key: 'deductStartTime',
      label: '扣费开始日期',
      width: '150'
    },
    {
      key: 'trialEndTime',
      label: '试用有效期',
      width: '150'
    },
    {
      key: 'formalEndTime',
      label: '正式有效期',
      width: '150'
    },
    {
      key: 'isCompel',
      label: '试用结束后',
      width: '90',
      formatter: (row, col, val) => { return isCompel[val] || '-' }
    },
    {
      key: 'createTime',
      label: '操作时间',
      width: '150'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}
export default defineComponent({
  name: '',
  components: { CTable },
  setup() {
    const router = useRouter()
    const data = reactive({
      dataSource: tableConfig()
    })

    const companyUuid = router.currentRoute.value.query.uuid

    const getList = async () => {
      data.dataSource.loading = true
      const p = {
        companyUuid
      }
      const [err, res] = await toResult(recordCompanyAPI(p))
      data.dataSource.loading = false
      if (err) return
      console.log(res)
      data.dataSource.data = res || []
      console.log(data.dataSource.data)
      data.dataSource.pagination.total = res.total
    }

    getList()

    return {
      ...toRefs(data)
    }
  }
})
