
import { defineComponent, reactive, toRefs, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { setEditEnterprise, getDetailOfEnterprise } from './api'
import { useRouter } from 'vue-router'
import CImgView from '@/components/c-img-view'
import { getFileUrl } from '@/api/common'
import { rules, economicsStatusMap, economicsTypeMap } from './config'
import Renewal from './renewal.vue'
import TrialTime from './TrialTime.vue'
import carNum from './carNum.vue'

import BasicDataComp from './components/basicData/index.vue'
import API from './components/API/index.vue'
import CompanyComp from './components/company/index.vue'
import AffiliatedSubsidiaries from './components/affiliatedSubsidiaries/index.vue'
import DeductionComp from './components/deduction/index.vue'
import PaymentComp from './components/payment/index.vue'
import { toResult } from '@/utils/toResult'

export default defineComponent({
  name: 'enterprise-info-detail',
  components: {
    CImgView,
    Renewal,
    BasicDataComp,
    API,
    CompanyComp,
    AffiliatedSubsidiaries,
    DeductionComp,
    PaymentComp,
    TrialTime,
    carNum,
  },
  setup() {
    const router = useRouter()
    const data = reactive({
      formData: ref<any>({
        bankRelated: [],
      }),
      rules,
      economicsStatusMap,
      economicsTypeMap,
      rowData: {},
      isRenewal: false,
      isTrialTime: false,
      isCarNum: false,
      tabsName: 'BasicDataComp',
      isImgViewer: false,
      currentImages: [],
    })
    const managePermissionJpgList = ref([])
    const businessLicenseJpgList = ref([])
    const getEnterpriseDetail = (uuid: object) => {
      getDetailOfEnterprise(uuid).then((res) => {
        data.formData = res.data
        data.rowData = res.data
        if ((data.formData as any).managePermissionJpg) {
          getFileUrl({ filename: res.data.managePermissionJpg }).then((res) => {
            managePermissionJpgList.value.push(res.data.url)
          })
        }
        if ((data.formData as any).businessLicenseJpg) {
          getFileUrl({ filename: res.data.businessLicenseJpg }).then((res) => {
            businessLicenseJpgList.value.push(res.data.url)
          })
        }
      })
    }
    const back = () => router.back()
    const formRef = ref<HTMLElement | any>(null)
    const creatSumbit = (): void => {
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          setEditEnterprise(data.formData).then(() => {
            ElMessage.success('修改成功')
            back()
          })
        } else {
          ElMessage.warning('表单校验不正确')
        }
      })
    }
    getEnterpriseDetail(router.currentRoute.value.query)

    const handleRenewal = () => {
      data.isRenewal = true
    }

    const getFileUrlHandler = async (filename) => {
      const [err, res] = await toResult(getFileUrl({ filename }))
      if (err) return
      console.log(res)
      data.isImgViewer = true
      data.currentImages = []
      data.currentImages.push(res.url || '')
    }

    const combination = (i) => {
      const arr = []
      i.forEach((item) => {
        arr.push(`${item.appIdName}（APPID：${item.appId}）`)
      })

      return arr.join(',')
    }

    const onToggle = (name) => {
      data[name] = !data[name]
    }

    return {
      formRef,
      ...toRefs(data),
      creatSumbit,
      getEnterpriseDetail,
      back,
      handleRenewal,
      managePermissionJpgList,
      businessLicenseJpgList,
      getFileUrlHandler,
      combination,
      onToggle,
    }
  },
})
