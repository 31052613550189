/*
 * @Descripttion:
 * @Author: panyufeng
 * @Date: 2024-09-29 15:27:43
 * @LastEditors: panyufeng
 * @LastEditTime: 2024-10-10 12:10:09
 */

import service from '@/utils/request'

// 企业违章转关联列表
export const getList = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterpriseViolationSettingCorrelation/list',
    data: params
  })

// 设置默认
export const settingDefault = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterpriseViolationSettingCorrelation/settingDefault',
    data: params
  })

// 解除关联
export const remove = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterpriseViolationSettingCorrelation/remove',
    data: params
  })
