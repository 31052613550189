
import CTable from '@/components/c-table'
import { getIndexConfig, getTableConfig, inputList } from './config'
// import { usePagination } from '@/hooks/usePagination'
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { getList, settingDefault, remove } from './api'
import { toResult } from '@/utils/toResult'
import { btnName } from '@/utils'
import { ElMessage, ElMessageBox } from 'element-plus'
export default defineComponent({
  components: {
    CTable,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
      default: false,
    },
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const data = reactive({
      inputList: inputList(),
      ...getIndexConfig(),
      isType: '',
      isedit: false,
      unitPrice: 0,
    })

    const tableConfig = reactive({
      ...getTableConfig(),
    })

    const getListFn = async () => {
      const p = {
        companyUuid: props.rowData.uuid,
      }

      tableConfig.loading = true
      const [err, res] = await toResult(getList(p))
      tableConfig.loading = false
      if (err) return
      tableConfig.data = res || []
      console.log(res[0].unitPrice)

      data.unitPrice = res[0].unitPrice
      // tableConfig.pagination.total = res.total
    }

    // 查询
    const handleSearch = (params) => {
      // tableConfig.pagination.currentPage = 1
      params && (data.queryForm = { ...params })
      getListFn()
    }

    // 重置
    const handleReset = (val) => {
      data.queryForm = val
    }

    const onConfirm = () => {
      console.log(tableConfig)
    }

    // 新增、修改 按钮操作
    const btnHandle = async (type, row) => {
      switch (type) {
        case 'unbundle': {
          ElMessageBox.confirm('确定吗', '确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            const p = {
              companyUuid: row.companyUuid,
              id: row.id,
            }
            await remove(p)
            ElMessage({
              type: 'success',
              message: '操作成功',
            })
            handleSearch({})
          })

          break
        }
        case 'default': {
          const p = {
            companyUuid: row.companyUuid,
            id: row.id,
          }
          ElMessage({
            type: 'success',
            message: '操作成功',
          })
          await settingDefault(p)

          handleSearch({})

          break
        }
      }
    }
    // const { pageCurrentChange, pageSizeChange } = usePagination(
    //   tableConfig.pagination,
    //   () => {
    //     getListFn()
    //   }
    // )

    onMounted(() => {
      handleSearch({})
    })

    return {
      ...toRefs(data),
      handleSearch,
      btnName,
      handleReset,
      tableConfig,
      btnHandle,
      // pageCurrentChange,
      // pageSizeChange,
      getListFn,
      onConfirm,
    }
  },
})
