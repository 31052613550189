
import { defineComponent, reactive, ref, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import { required } from '@/utils/rules'
import { basicDataAPI, editCarNumAPI } from './api'
import { toResult } from '@/utils/toResult'

import { useRouter } from 'vue-router'
const numR = {
  pattern: /^(([0-9][0-9]*))$/,
  message: '请输入数字',
  trigger: ['blur', 'change']
}
export default defineComponent({
  props: {
    visible: Boolean,
    rowData: Object
  },
  setup(props, { emit }) {
    const router = useRouter()
    const data = reactive({
      dialogVisible: props.visible,
      form: ref<any>({}),
      formRef: null,
      rules: {
        carBaseNum: [required, numR],
        carChargeNum: [required, numR]
      },
      basicDataRow: {}
    })
    console.log(props.rowData)

    const handleClose = () => {
      emit('update:visible', false)
      data.formRef.resetFields()
    }
    // 禁用当前时间以后的时间
    const endDisabledDate = time => {
      console.log(time)
      if (props.rowData.formalEndTime) {
        return time.getTime() < new Date(props.rowData.formalEndTime).getTime()
      }
    }

    const getbasicData = async () => {
      const [err, res] = await toResult(basicDataAPI({ companyUuid: router.currentRoute.value.query.uuid }))
      if (err) return false
      console.log(res)
      data.basicDataRow = res || {}
    }
    getbasicData()

    // 保存提交
    const handleSubmit = () => {
      data.formRef.validate(valid => {
        if (valid) {
          const params = {
            ...data.form,
            companyUuid: props.rowData.uuid

          }

          editCarNumAPI(params).then(() => {
            ElMessage({
              type: 'success',
              message: '操作成功'
            })
            handleClose()
            // emit('getListFn')
          })
        }
      })
    }

    return {
      ...toRefs(data),
      handleClose,
      handleSubmit,
      endDisabledDate
    }
  }
})
