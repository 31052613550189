import { enumToOption } from '@/utils'
// import { h } from 'vue'
// import { ElTag } from 'element-plus'

// 费用项目
export enum feeType {
  车辆使用费 = 1,
  基础平台费,
  增值服务费
}

// 状态
export enum state {
  待缴费,
  缴费成功,
  缴费失败
}

// 缴费类型
export enum paymentType {
  按年 = 1,
  按天,
  按次
}

// 原因说明
export enum payChannel {
  支付宝 = 1,
  微信,
  工商,
  银行汇款,
  系统,
}

export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: true,
    isIndex: false,
    // table 索引 label
    indexLabel: '序号',
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'uuid',
      label: '缴费编号',
      width: '140'
    },
    {
      key: 'feeType',
      label: '费用项目',
      width: '140',
      formatter: (row, col, val) => {
        return feeType[val]
      }
    },
    {
      key: 'paymentType',
      label: '缴费类型',
      width: '140',
      formatter: (row, col, val) => {
        let res = '-'
        if (val) {
          res = paymentType[val] + '(' + row.paymentPeriod + ')' || '-'
        }
        return res
      }
    },
    {
      key: 'payableAmount',
      label: '应缴总金额',
      width: '140'
    },
    {
      key: 'payAmount',
      label: '实缴金额（元）',
      width: '140'
    },
    {
      key: 'givingMoney',
      label: '优惠金额',
      width: '140'
    },
    {
      key: 'actualMoney',
      label: '实到金额（元）',
      width: '140'
    },
    {
      key: 'payChannel',
      label: '缴费渠道',
      width: '140',
      formatter: (row, col, val) => {
        return payChannel[val]
      }
    },
    {
      key: 'payRemark',
      label: '缴费备注',
      width: '140',
      formatter: (row, col, val) => val || '-'
    },
    {
      key: 'state',
      label: '状态',
      width: '100',
      slot: 'state'
      // formatter: (row, col, val) => {
      //   return h(ElTag, {
      //     size: 'medium',
      //     type: val === 1 ? 'success' : 'danger'
      //   }, state[val])
      // }
    },
    // {
    //   key: 'internalRemark',
    //   label: '原因说明',
    //   width: '150',
    //   formatter: (row, col, val) => val || '-'
    // },
    {
      key: 'createTime',
      label: '创建时间',
      width: '150'
    },
    {
      slot: 'action',
      label: '操作',
      width: '140',
      fixed: 'right'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

// 页面搜索和基础变量
export const getIndexConfig = () => {
  // 搜索变量
  const inputList = [
    {
      type: 'select',
      label: '费用项目',
      key: 'feeType',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 200,
      options: enumToOption(feeType),
      rules: {
        type: 'number'
      }
    },
    {
      type: 'select',
      label: '状态',
      key: 'state',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 200,
      options: enumToOption(state),
      rules: {
        type: 'number'
      }
    },
    {
      type: 'datePicker',
      label: '创建时间',
      key: 'dateTimeArea',
      placeholder: '请选择',
      labelWidth: 100,
      inputWidth: 260,
      format: 'YYYY-MM-DD',
      dateType: 'daterange',
      // 开启日期范围后生效
      unlinkPanels: false,
      rangeSeparator: '-',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      rules: {
        // 默认 date 开启范围 array
        type: 'array'
      }
    }
  ]

  // 页面基础变量
  const page = {
    queryForm: {}, // 搜索参数
    visible: false, // 弹框显示
    title: '新建员工',
    rowData: null // 单行数据
  }

  return {
    inputList,
    ...page
  }
}
