
import { defineComponent, reactive, ref, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import { required } from '@/utils/rules'
import { editTrialTimeAPI } from './api'
import dayjs from 'dayjs'
import moment from 'moment'

export default defineComponent({
  props: {
    visible: Boolean,
    rowData: Object
  },
  setup(props, { emit }) {
    const data = reactive({
      dialogVisible: props.visible,
      form: ref<any>({}),
      formRef: null,
      rules: {
        trialEndTime: [required],
        deductStartTime: [required]
      }
    })

    console.log(props.rowData)

    const handleClose = () => {
      emit('update:visible', false)
      data.formRef.resetFields()
    }
    // 禁用当前时间以后的时间
    const endDisabledDate = time => {
      if (props.rowData.formalEndTime) {
        return time.getTime() >= new Date(props.rowData.formalEndTime).getTime()
      }
    }

    // 保存提交
    const handleSubmit = () => {
      data.formRef.validate(valid => {
        if (valid) {
          if (moment(data.form.deductStartTime).diff(moment(data.form.trialEndTime)) < 0) {
            ElMessage.warning('计费开始日期 大于等于 新试用到期日期')
            return false
          }
          console.log(moment(data.form.formalEndTime).diff(moment(data.form.deductStartTime)))

          if (moment(props.rowData.formalEndTime).diff(moment(data.form.deductStartTime)) < 0) {
            ElMessage.warning('计费开始日期 小于等于 正式结束日期')
            return false
          }

          if (moment(props.rowData.formalEndTime).diff(moment(data.form.trialEndTime)) < 0) {
            ElMessage.warning('新试用到期日期必须小于正式结束日期')
            return false
          }

          if (moment(props.rowData.signTime).diff(moment(data.form.trialEndTime)) > 0) {
            ElMessage.warning('新试用到期日期必须大于等于日期' + props.rowData.signTime)
            return false
          }

          const params = {
            companyUuid: props.rowData.uuid,
            deductStartTime: dayjs(data.form.deductStartTime).format('YYYY-MM-DD'),
            trialEndTime: dayjs(data.form.trialEndTime).format('YYYY-MM-DD')
          }

          editTrialTimeAPI(params).then(() => {
            ElMessage({
              type: 'success',
              message: '操作成功'
            })
            handleClose()
            // emit('getListFn')
          })
        }
      })
    }

    return {
      ...toRefs(data),
      handleClose,
      handleSubmit,
      endDisabledDate
    }
  }
})
