
import { defineComponent, reactive, ref, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import { required } from '@/utils/rules'
import { renewApi } from './api'
import dayjs from 'dayjs'

export default defineComponent({
  props: {
    visible: Boolean,
    rowData: Object
  },
  setup(props, { emit }) {
    const data = reactive({
      dialogVisible: props.visible,
      form: ref<any>({}),
      formRef: null,
      rules: {
        formalEndTime: [required]
      }
    })

    const handleClose = () => {
      emit('update:visible', false)
      data.formRef.resetFields()
    }
    // 禁用当前时间以后的时间
    const endDisabledDate = time => {
      console.log(time)
      // if (props.rowData.formalEndTime) {
      //   return time.getTime() <= new Date(props.rowData.formalEndTime).getTime()
      // }
    }

    // 保存提交
    const handleSubmit = () => {
      data.formRef.validate(valid => {
        if (valid) {
          const params = {
            companyUuid: props.rowData.uuid,
            formalEndTime: dayjs(data.form.formalEndTime).format('YYYY-MM-DD') + ' 23:59:59'
          }

          renewApi(params).then(() => {
            ElMessage({
              type: 'success',
              message: '操作成功'
            })
            handleClose()
            // emit('getListFn')
          })
        }
      })
    }

    return {
      ...toRefs(data),
      handleClose,
      handleSubmit,
      endDisabledDate
    }
  }
})
