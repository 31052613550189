
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  name: 'CImgView',
  props: {
    images: {
      type: Array
    }
  },
  setup(props) {
    const state = reactive({
      isViewer: false,
      currentImages: []
    })
    /**
     * @description: 关闭图片预览
     * @param {*}
     * @return {void}
     */
    const handleViewerClose = (): void => {
      state.isViewer = !state.isViewer
    }

    /**
     * @description:点击图片
     * @param {*}
     * @return {void}
     */
    const clickImg = (): void => {
      state.isViewer = !state.isViewer
      state.currentImages = props.images
    }

    return {
      ...toRefs(state),
      handleViewerClose,
      clickImg
    }
  }
})
